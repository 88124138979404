<template>
	<div style="height: 100%;overflow: auto;">
		<div class="topMain">
			<div class="top ">
				<div class="topOne">
					考试试卷
				</div>
				<div class="topTwo">
					<!-- <span>考试时间：{{exam.examTime}}</span> -->
					<span>考试总分：{{exam.totalScore}}分</span>
					<span>考试题数：{{exam.totalNum}}题</span>
					<span class="buttonCls">考试得分：<span>{{stuScore}}分</span></span>
				</div>
			</div>
		</div>
		<div class="content ">
			<div class="contentLeft">
				<div class="card" v-if="exam.exams1.radio.length!==0">
					<p>一、单项选择题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.radio" :key="it.id">
						<h3>{{i+1}}.{{it.examName}}( {{it.answer}} ) （{{it.score}}分）</h3>
						<el-checkbox-group v-model="it.answerArr">
							<el-checkbox disabled :label="item.idStr" v-for="(item) in it.options" :key="item.id">
								{{item.idStr}}.{{item.item}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.judge.length!==0">
					<p>二、判断题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.judge" :key="it.id">
						<h3>{{i+1}}.{{it.examName}}( {{!it.answer ? '' : it.answer===1 ? '对' : '错'}} ) （{{it.score}}分）
						</h3>
						<el-checkbox-group v-model="it.answerArr">
							<el-checkbox disabled :label="1">对</el-checkbox>
							<el-checkbox disabled :label="2">错</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.multiple.length!==0">
					<p>三、多项选择题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.multiple" :key="i">
						<h3>{{i+1}}.{{it.examName}}( {{it.answer}} )（{{it.score}}分）</h3>
						<el-checkbox-group v-model="it.answerArr[0]">
							<el-checkbox disabled :label="item.idStr" v-for="(item) in it.options" :key="item.id">
								{{item.idStr}}.{{item.item}}
							</el-checkbox>

						</el-checkbox-group>
					</div>
				</div>
				<div class="card" v-if="exam.exams1.brief.length!==0">
					<p>四、主观题</p>
					<div class="cardContent" v-for="(it,i) in exam.exams1.brief" :key="i">
						<h3>{{i+1}}.{{it.examName}} （{{it.score}}分）</h3>
						<el-input disabled type="textarea" :rows="6" v-model="it.examAnswer">
						</el-input>
					</div>
				</div>
			</div>
			<div class="contentRight" :class="isFiexd ? 'isFiexd' : ''">
				<div class="rightTop">
					<div>答题卡 ({{exam.totalNum - todoArr.length}}/{{exam.totalNum}})</div>
					<div>
						<p>正确<span></span></p>
						<p>错误<span></span></p>
						<p>未做<span></span></p>
					</div>
				</div>
				<div class="rightBottom">
					<div class="bottomCard">
						<h3>一、单项选择题</h3>
						<div class="cardSpan">
							<span
								:style="it.answer === '' ? 'background:#fff' :it.answer === it.examAnswer ? 'background:#08A500' : it.answer !== it.examAnswer ? 'background:#39AEFF' :  ''"
								v-for="(it,i) in examOld.exams1.radio" :key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>二、判断题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in examOld.exams1.judge"
								:style="it.answer === '' ? 'background:#fff' :it.answer === it.examAnswer ? 'background:#08A500' : it.answer !== it.examAnswer ? 'background:#39AEFF' :  ''"
								:key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>三、多项选择题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in examOld.exams1.multiple"
								:style="it.answer === '' ? 'background:#fff' :it.answer === it.errorArr ? 'background:#08A500' : it.answer !== it.errorArr ? 'background:#39AEFF' :  ''"
								:key="i">{{i+1}}</span>
						</div>
					</div>
					<div class="bottomCard">
						<h3>四、主观题</h3>
						<div class="cardSpan">
							<span v-for="(it,i) in examOld.exams1.brief"
								:style="it.answer === '' ? 'background:#fff' :it.examScore === it.score ? 'background:#08A500' : it.examScore !== it.score ? 'background:#39AEFF' :  ''"
								:key="i">{{i+1}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				radio: ['1'],
				checkList: ['A', 'D'],
				textarea: '',
				isFiexd: false,
				exam: {
					exams1: {
						brief: [],
						judge: [],
						multiple: [],
						radio: [],
					}
				},
				todoArr: [],
				examOld: {
					exams1: {
						brief: [],
						judge: [],
						multiple: [],
						radio: [],
					}
				},
				list: '',
				stuScore: 0
			}
		},
		created() {
			this.list = localStorage.getItem('perId')
			this.$http.post('paperItem', {
				paperId: this.list
			}).then(res => {
				this.examOld = res.data
				this.exam = res.data
				this.exam.exams1.radio.map(it => {
					it.answerArr = []
					it.answerArr.push(it.examAnswer)
					if (it.answer === '') {
						this.todoArr.push(it)
					}
					return it
				})
				this.exam.exams1.judge.map(it => {
					it.answerArr = []
					if (it.answer === '对') {
						it.answer = 1
					}
					if (it.answer === '错') {
						it.answer = 2
					}
					if (it.examAnswer === '对') {
						it.examAnswer = 1
					} else {
						it.examAnswer = 2
					}
					it.answerArr.push(it.examAnswer)
					if (it.answer === '') {
						this.todoArr.push(it)
					}
					return it
				})
				this.exam.exams1.multiple.map(it => {
					it.answerArr = []
					it.examAnswer = it.examAnswer.split('')
					it.answerArr.push(it.examAnswer)
					if (it.answer === '') {
						this.todoArr.push(it)
					}
					return it
				})
				this.exam.exams1.brief.map(it => {
					if (it.answer === '') {
						this.todoArr.push(it)
					}
					return it
				})
				this.examOld.exams1.multiple.map(it => {
					it.errorArr = it.examAnswer.toString().replace(/,/g, "")
				})
			})
		},
		computed: {
			allExam() {
				return this.exam.exams
			}
		},
		watch: {
			allExam(val) {
				if (val) {
					let arr = []
					for (let i in val) {
						arr.push(...val[i])
					}
					this.stuScore = arr.reduce((pre, cur) => {
						return pre + cur.score
					}, 0)
				}
			}
		},
		mounted() {
			//监听滚动事件
			window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			evt(it) {
				console.log(it)
			},
			handleCurrentChange(e) {
				console.log(e);
			},
			//滚动事件
			handleScroll(e) {
				if (e.target.scrollTop > 134) {
					this.isFiexd = true
				}
				if (e.target.scrollTop <= 134) {
					this.isFiexd = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.main {
		width: 1820px;
		margin: 0 auto;
	}

	.topMain {
		height: 94px;
		background: #FFFFFF;
		box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.05);
		margin-bottom: 40px;
		width: 100%;
		padding: 0 40px;
	}

	.top {
		display: flex;
		justify-content: space-between;
		line-height: 94px;

		.topOne {
			font-size: 30px;
			font-family: Adobe Heiti Std;
			font-weight: bold;
			color: #111111;
		}

		.topTwo {
			display: flex;

			span {
				display: block;
				margin-left: 70px;
				font-size: 22px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #111111;
			}

			.buttonCls {
				display: flex;

				span {
					margin-left: 0;
					color: #39AEFF;
				}
			}
		}
	}

	.content {
		display: flex;
		justify-content: space-between;
		padding: 0 43px 0 47px;

		.contentLeft {
			// width: 1335px;
			flex: 2;
			overflow-y: auto;

			.card {
				margin-bottom: 30px;

				p {
					height: 47px;
					background: #DCDCDC;
					font-size: 24px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #333333;
					line-height: 47px;
					padding-left: 10px;
					margin-bottom: 20px;
				}

				.cardContent {
					margin-left: 27px;
				}

				h3 {
					width: 1308px;
					height: 47px;
					background: #DCDCDC;
					font-size: 18px;
					font-family: SourceHanSerifCN;
					font-weight: 500;
					color: #333333;
					line-height: 47px;
					margin-bottom: 10px;
					padding-left: 10px;
				}

				/deep/.el-radio-group {
					margin-left: 20px;
				}

				/deep/.el-radio {
					display: block;
					margin-bottom: 15px;
					font-size: 16px;
					font-family: SourceHanSerifCN;
					font-weight: 400;
					color: #333333;
				}

				/deep/.el-radio__input.is-checked+.el-radio__label {
					color: #333333;
				}

				/deep/.el-radio__input.is-checked .el-radio__inner {
					border-color: #08A500;
					background: #08A500;
				}

				/deep/.el-checkbox {
					display: block;
					margin-bottom: 15px;
					margin-left: 20px;
				}

				/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
					color: #333333;
				}

				/deep/.el-checkbox__inner {
					border-radius: 100%;
				}

				/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
				.el-checkbox__input.is-indeterminate .el-checkbox__inner {
					background-color: #08A500;
					border-color: #08A500;
				}

				/deep/.el-radio__input.is-disabled+span.el-radio__label {
					color: #333333;
				}

				/deep/.el-checkbox__input.is-disabled+span.el-checkbox__label {
					color: #333333;
				}

				.isChecked {

					/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
					.el-checkbox__input.is-indeterminate .el-checkbox__inner {
						background-color: #39AEFF;
						border-color: #39AEFF;
					}
				}
			}
		}

		.contentRight {
			width: 452px;
			height: 750px;
			background: #FFFFFF;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.09);
			margin-left: 30px;

			.rightTop {
				display: flex;

				div:nth-child(1) {
					width: 226px;
					height: 65px;
					background: #041366;
					font-size: 24px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					text-align: center;
					line-height: 65px;
				}

				div:nth-child(2) {
					width: 226px;
					height: 65px;
					display: flex;
					justify-content: space-between;
					padding: 9px 28px;
					flex-wrap: wrap;

					p {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #333;
						display: flex;
					}

					p:nth-child(1) {
						span {
							display: block;
							height: 12px;
							width: 12px;
							background: #08A500;
							border-radius: 50%;
							margin-left: 17px;
							margin-top: 4px;
						}
					}

					p:nth-child(2) {
						span {
							display: block;
							width: 12px;
							height: 12px;
							background: #39AEFF;
							border-radius: 50%;
							margin-left: 17px;
							margin-top: 4px;
						}
					}

					p:nth-child(3) {
						span {
							display: block;
							width: 12px;
							height: 12px;
							border: 1px solid #DCDCDC;
							border-radius: 50%;
							margin-left: 17px;
							margin-top: 4px;
						}
					}

				}
			}

			.rightBottom {
				padding: 0 30px;
				margin-top: 50px;

				.bottomCard {
					margin-bottom: 20px;
				}

				h3 {
					font-size: 24px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #333333;
				}

				.cardSpan {
					display: flex;
					flex-wrap: wrap;
					margin-top: 20px;

					span {
						display: block;
						width: 30px;
						height: 30px;
						border: 1px solid #DCDCDC;
						margin-right: 6px;
						margin-bottom: 6px;
						text-align: center;
						line-height: 30px;
						font-size: 14px;
						font-family: SourceHanSerifCN;
						font-weight: 400;
						color: #333333;
						cursor: pointer;
					}

					.spanStyle {
						position: relative;
						background: #39AEFF;
						color: #FFFFFF;
					}
				}
			}
		}

		// .isFiexd {
		// 	position: fixed;
		// 	right: 59px;
		// 	top: 30px;
		// }
	}

	/deep/.el-pagination {
		text-align: center;
		margin: 30px 0;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #39AEFF;
	}
</style>
